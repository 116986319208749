import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})

export class ResePwdServiceService {
  username: any
  ROTP: any;
  public isVerifyCustomer: boolean = false
  errorMessage: string = '';
  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }
  getOTPservice(data) {
    return this.httpClient.post<any>(baseApiUrl + 'subscriber/forgotPassword', data);
  }
  verifyOTPservice(data): Observable<any> {
    this.username = data.username;
    return this.httpClient.post<any>(baseApiUrl + 'subscriber/validateForgotPassword', data);
  }
  resetPassword(data) {
    return this.httpClient.post<any>(baseApiUrl + 'subscriber/self/updatePassword', data);
  }
  navigateUrl(url) {
    this.router.navigate([url]);
  }
  setUser(username: string) {
    localStorage.setItem("custUsername", username);
  }
  getUser() {
    return localStorage.getItem("custUsername");
  }
  isAuthenticate() {
    if (this.getUser() != null) {
      return true;
    } else {
      return false;
    }
  }
  msgError(payload) {
    this.errorMessage = payload;
  }
  isOTPverified() {
    if (localStorage.getItem('OTPVerified')) {
      return true;
    } else {
      return false;
    }
  }
  getdataById(data:any){
    const post_url=baseApiUrl+'lead/operation/getBasicDetailsMD5';
    return this.httpClient.post<any>(post_url,data, { headers: httpOptions.headers });
  }

  getKYCVerificationStatus(data){
    const post_url=baseApiUrl+'lead/operation/getLeadKYCDetails';
    return this.httpClient.post<any>(post_url,data, { headers: httpOptions.headers });
  }

  OTPverifiedServiceAtSave(data) {
    //let data = { mobile:mobile, mobileotp: Otp }
    const post_url = baseApiUrl + 'lead/operation/mobileOtpVerification';
    return this.httpClient.post<any>(post_url, data, { headers: httpOptions.headers });
  }

  MobileVerification(data){
    const post_url = baseApiUrl + 'lead/operation/mobileVerification';
    return this.httpClient.post<any>(post_url,data)
  }
  getLeadSource(){
    const get_url = baseApiUrl + 'lead/operation/commonList/generic/leadSource';
    return this.httpClient.get<any>(get_url);                                                                                                                                                      
  }
  getRelativeSource(){
    const get_url = baseApiUrl + 'lead/operation/commonList/generic/sourceOfRelative';
    return this.httpClient.get<any>(get_url);                                                                                                                                                      
  }
  getpreviousIspList(){
    const get_url = baseApiUrl + 'lead/operation/commonList/generic/previousIspType';
    return this.httpClient.get<any>(get_url);                                                                                                                                                      
  }
  getonuList(){
    const get_url = baseApiUrl + 'lead/operation/commonList/generic/onuType';
    return this.httpClient.get<any>(get_url);                                                                                                                                                      
  }
  sessionexpire(data){
    const post_url = baseApiUrl + 'lead/operation/checkLeadURLSession';
    return this.httpClient.post<any>(post_url,data)
  }

  checkMobileVerify(id){
    const get_url = baseApiUrl + 'lead/operation/checkMobileVerified/'+id;
    return this.httpClient.get<any>(get_url);   
  }
}
