import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Autosize } from 'ng-autosize';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ErrorComponent } from './pages/error/error.component';
import { AppInterceptor } from './app.interceptor';
import { LoginService } from './pages/login/login.service';
import { AppGuard } from './app.guard';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap';

// import {GoogleChartsModule} from 'angular-google-charts';




const APP_PROVIDERS = [AppConfig, LoginService, AppGuard];

import {
  MatDatepickerModule,
  MatNativeDateModule,
  DateAdapter,
  MatTooltipModule,
  MatCheckboxModule
} from "@angular/material";
import { DateFormat } from "./services/date-format";
import { PlansModule } from './pages/plans/plans.module';
import { PaymentsModule } from './pages/payments/payments.module';
import { PaymentMidpageComponent } from './components/payment-midpage/payment-midpage.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { EnvServiceProvider } from '../env.service.provider';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';
import { CustomerBasicDetailsComponent } from './components/customer-basic-details/customer-basic-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomModalModule } from './components/custom-modal';
import { SearchAutocompleteComponent } from './components/search-autocomplete/search-autocomplete.component';
import { PubSubService } from './services/pubsub/pubsub.service';
import { ResetPasswordModule } from './pages/reset-password/reset-password.module';
import { ThankyouPageComponent } from './components/thankyou-page/thankyou-page.component';
import { CustPaymentComponent } from './pages/cust-payment/cust-payment/cust-payment.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SessionExpireComponent } from './components/session-expire/session-expire.component';
import { GoogleMapComponent } from './components/customer-basic-details/google-map/google-map/google-map.component';
import { AgmCoreModule } from '@agm/core';
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent,SearchAutocompleteComponent,GoogleMapComponent, ErrorComponent, Autosize,PaymentMidpageComponent,PaymentStatusComponent, PaymentLinkComponent, CustomerBasicDetailsComponent, ThankyouPageComponent,CustPaymentComponent, SessionExpireComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CustomModalModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    HttpModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyC86qcum8RotunEfPq74NBvaccbgO3HFQg',libraries:['places']
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    ResetPasswordModule,
    MatCheckboxModule,
    GoogleMapsModule
    // PlansModule,
    // PaymentsModule
  ],
  entryComponents:[],
  providers: [
    PubSubService,
    APP_PROVIDERS,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: DateFormat },
    EnvServiceProvider
  ],
})
export class AppModule {

}

// // ---------------------------------- KS CODE -----------------------------------------------------

// import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { FormsModule } from '@angular/forms';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { RouterModule, PreloadAllModules } from '@angular/router';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';
// import { Autosize } from 'ng-autosize';

// import { ROUTES } from './app.routes';
// import { AppComponent } from './app.component';
// import { AppConfig } from './app.config';
// import { ErrorComponent } from './pages/error/error.component';
// import { AppInterceptor } from './app.interceptor';
// import { LoginService } from './pages/login/login.service';
// import { AppGuard } from './app.guard';
// import { HttpModule } from '@angular/http';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { DatePipe } from '@angular/common';
// import { TooltipModule } from 'ngx-bootstrap';

// const APP_PROVIDERS = [AppConfig, LoginService, AppGuard];

// import {
//   MatDatepickerModule,
//   MatNativeDateModule,
//   DateAdapter
// } from "@angular/material";
// import { DateFormat } from "./services/date-format";

// @NgModule({
//   bootstrap: [AppComponent],
//   declarations: [AppComponent, ErrorComponent, Autosize],
//   imports: [
//     BrowserModule,
//     BrowserAnimationsModule,
//     FormsModule,
//     HttpClientModule,
//     HttpModule,
//     TooltipModule.forRoot(),
//     ToastrModule.forRoot(),
//     RouterModule.forRoot(ROUTES, {
//       useHash: true,
//       preloadingStrategy: PreloadAllModules,
//     }),
//     NgbModule,
//     MatDatepickerModule,
//     MatNativeDateModule
//     ],
//   providers: [
//     APP_PROVIDERS,
//     DatePipe,
//     {
//       provide: HTTP_INTERCEPTORS,
//       useClass: AppInterceptor,
//       multi: true,
//     },
//     { provide: DateAdapter, useClass: DateFormat }
//   ],
// })
// export class AppModule {

// }
