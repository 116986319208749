import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  @Output() Latitude = new EventEmitter<any>();
  @Output() longitude = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  public searchControl: FormControl = new FormControl();
  markerPosition: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  showMap = false;
  constructor(private mapsApiloader:MapsAPILoader,private ngZone:NgZone) { }

  ngOnInit() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          this.markerPosition = currentLocation;
          this.center = currentLocation;
          
        });
      }
  }
  onMapClick(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.markerPosition = event.latLng.toJSON();
      this.Latitude.emit(this.markerPosition.lat) 
      this.longitude.emit(this.markerPosition.lng)
    }
  }
  ngAfterViewInit(){
     this.getlocation()
  }
  onblur(){
this.getlocation()
  }
  getlocation(){
  this.mapsApiloader.load().then(()=>{
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['geocode'],
      componentRestrictions:{'country':'IN'}
 });
 autocomplete.addListener('place_changed', () => {
  this.ngZone.run(()=>{
    const place:google.maps.places.PlaceResult=autocomplete.getPlace();
    if(place.geometry==undefined || place.geometry===null){
        return;
    } 
    const places = autocomplete.getPlace();
    if (places.geometry && places.geometry.location) {
      const location = places.geometry.location.toJSON();
      this.markerPosition = location;
      this.center = location;
    }
    this.Latitude.emit(this.markerPosition.lat)
    this.longitude.emit(this.markerPosition.lng)
  })
});
  })
  }
}