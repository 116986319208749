import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Verification } from '../model/Verification';
import { ResePwdServiceService } from '../rese-pwd-service.service';
import { HostListener } from '@angular/core';
import { HostBinding } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../../../app.config';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  backEndImage: string;
  BackendFinalurl: string;
  otpFlag: boolean = false;
  mobileOtp: number;
  mobileverified: boolean = false;
  submitted: boolean = false;
  id: string;
  isDisableGenOTP: boolean = false;
  isDisableVerify: boolean = false;
  entityType: boolean = false;
  userFullName: string;
  config;
  mobileNo: string;
  md5Id: string;
  dig1: number;
  dig2: number;
  dig3: number;
  dig4: number;
  dig5: number;
  dig6: number;
  otpFetched: string;
  display;
  public hideDisplay: boolean = false
  isVerifybtn: boolean = true;
  lasttwoDigit: string;
  firsttwoDigit: string;
  mobForm = new FormGroup({
    mobile: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    otp: new FormControl(null),
  });
  baseUrl:any;

  constructor(
    private appConfig: AppConfig,
    private route: ActivatedRoute,
    private tosterservice: ToastrService,
    private mobileService: ResePwdServiceService,
    private router: Router,
    private elementRef: ElementRef,
    private resetPasswordService:ResePwdServiceService,
  ) {
    this.config = appConfig.getConfig();
    this.backEndImage = this.appConfig.config.baseURLApi;
    this.BackendFinalurl = this.backEndImage.split('/')[2];
    //this.timer(1);
  }

  ngOnInit() {
    this.entityType = this.router.url.includes('partner');
    this.md5Id = this.route.snapshot.paramMap.get('id');
    // this.getKYCStatus();
  }

  checkSession(){
    const baseUrl = `${location.protocol}//${location.host}`;
    const currentUrl = this.router.url;
    const fullUrl = `${baseUrl}${'/#'}${currentUrl}`;
    if (fullUrl) {
      const urlFromBackend = fullUrl
      const hashIndex = urlFromBackend.indexOf('#')
      const nextSlashIndex = urlFromBackend.indexOf('/', hashIndex + 2)
      this.baseUrl = urlFromBackend.substring(0, nextSlashIndex + 1);
      let obj = {
        "url": this.baseUrl,
        "leadMD5Token": this.md5Id
      }
      this.resetPasswordService.sessionexpire(obj).subscribe((res) => {
        if (res.responseCode == 200) {
          if (res.data.sessionExpired) {
            this.router.navigate(['/session-expire'])
            return
          }
        }
      })
    }
  }

  timer(minute?) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.isVerifybtn = false;
        this.hideDisplay = true
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }

  getKYCStatus() {
    let data = {
      id: this.md5Id,
      type: this.entityType ? 'partner' : 'customer'
    }
    this.mobileService.getKYCVerificationStatus(data).subscribe(res => {
      if (res.responseCode == 200) {
        if (res.data.isMobileVerified && res.data.isAadhaarVerified && res.data.isKycDetailsAdded) {
          if (!this.entityType) {
            this.router.navigate(['thankYouPage', this.md5Id]);
          } else {
            this.router.navigate(['thankYouPage/partner', this.md5Id]);
          }
        }
        else if (res.data.isMobileVerified && !res.data.isAadhaarVerified && !res.data.isKycDetailsAdded) {
          if (!this.entityType) {
            this.router.navigate(['aadhar/', this.md5Id]);
          } else {
            this.router.navigate(['aadhar/partner', this.md5Id]);
          }
        }
        else if (!res.data.isMobileVerified) {
          this.getDataById();
        }
        else if (!res.data.isKycDetailsAdded) {
          if (res.data.isAadhaarVerified) {
            if (!this.entityType) {
              this.router.navigate(['aadhardetails', this.md5Id]);
            } else {
              this.router.navigate(['aadhardetails/partner', this.md5Id]);
            }
          }
          if (!res.data.isAadhaarVerified) {
            if (!this.entityType) {
              this.router.navigate(['manualkyc', this.md5Id]);
            } else {
              this.router.navigate(['manualkyc/partner', this.md5Id]);
            }
          }
        }
      }
      else {
        this.tosterservice.error(res.responseMessage);
      }
    })
  }

  getDataById() {
    let data = {
      id: this.md5Id,
      type: this.entityType ? 'partner' : 'customer'
    }
    this.mobileService.getdataById(data).subscribe((res) => {
      if (res && res.responseCode === 200) {
        this.userFullName = res.data.userName;
        this.mobForm.get('mobile').setValue(res.data.mobileNo);
        this.lasttwoDigit = res.data.mobileNo.substr(8);
        this.firsttwoDigit = res.data.mobileNo.slice(0, 2);
      } else {
        this.tosterservice.error(res.responseMessage)
      }
    }, err => {
      this.tosterservice.error(err.error.status + " " + err.error.ERROR)
    })
  }

  // sentMobileNumberTogetOTP(){
  // if(this.mobForm.invalid){
  //   this.submitted=true;
  //   this.otpFlag=false;
  //   this.tosterservice.error('Please Enter Mobile number')
  //   return;
  // }
  // if(this.mobForm.valid && this.submitted!==true){
  //   this.otpFlag=true;
  //  this.tosterservice.success('OTP Sent Successfully')
  // } else{
  //   this.otpFlag=true;
  //   this.tosterservice.error('Failed to Sent OTP.Please try Again')
  // }
  // }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  sentMobileNumberTogetOTP() {
    this.isDisableGenOTP = true;
    if (this.mobForm.invalid) {
      this.submitted = true;
      this.otpFlag = false;
      this.tosterservice.error('Please Enter Vaild Mobile number')
      this.isDisableGenOTP = false;
      return;
    } else {
      let data = {
        leadType: this.entityType ? 'partner' : 'customer',
        mobile: String(this.mobForm.get('mobile').value),
        md5Token: this.md5Id
      }
      this.mobileService.MobileVerification(data).subscribe(res => {
        if (res.responseCode !== 200) {
          this.tosterservice.error(res.responseMessage);
          this.isDisableGenOTP = false;
        } else {
          if (res.data == 'success') {
            this.tosterservice.success('OTP sent successfully')
            this.otpFlag = true;
            this.timer(1);
            this.hideDisplay=false
            this.convertToAsterisks(this.mobileNo)
            this.isVerifybtn = true;
          }
        }
      })
    }
  }
  convertToAsterisks(inputString: string): string {
    if (inputString.length === 10) {
      this.mobileNo = inputString.slice(0, 2) + "******" + inputString.slice(-2);
      console.log(this.mobileNo)
    } else {
      return "Invalid input string length";
    }
  }

  inputDig(data) {
    let element;
    if (data.data) {
      element = data.srcElement.nextElementSibling;
    } else {
      //element=data.srcElement.previousElementSibling;
    }
    if (element == null) {
      return
    } else {
      element.focus()
    }
  }

  keyUpDig(data) {
    let element;
    if (data.code !== 'Backspace') {
      //element=data.srcElement.nextElementSibling;
    } else if (data.code == 'Backspace') {
      element = data.srcElement.previousElementSibling;
    }
    if (element == null) {
      return
    } else {
      element.focus()
    }
  }

  verifyMobileOtp() {
    this.isDisableVerify = true;
    this.otpFetched = String(this.dig1) + String(this.dig2) + String(this.dig3) + String(this.dig4) + String(this.dig5) + String(this.dig6);
    if (this.otpFetched.toString().length === 6) {
      this.VerifiedOTP();
      //this.tosterservice.success('Mobile Verified Successfully')
      //this.otpFlag=true;
      this.dig1 = null
      this.dig2 = null
      this.dig3 = null
      this.dig4 = null
      this.dig5 = null
      this.dig6 = null
    } else {
      this.isDisableVerify = false;
      this.tosterservice.error('Invalid OTP Provided')
      //this.otpFlag=false;
    }
  }

  VerifiedOTP() {
    if (this.otpFetched) {
      let data = {
        mobileotp: this.otpFetched,
        mobile: String(this.mobForm.get('mobile').value),
        leadType: this.entityType ? 'partner' : 'customer',
        md5Token: this.md5Id
      }
      this.mobileService.OTPverifiedServiceAtSave(data).subscribe(res => {
        if (res.responseCode === 200) {
          if (res.data === 'success') {
            this.otpFetched = null;
            this.mobileService.isVerifyCustomer = true
            if (this.router.url.includes('/customer-basic-details-verify-customer/')) {
              this.router.navigate(['customer-basic-details', this.md5Id])
            }
            else if(this.router.url.includes('/kyc-details-verify-customer/')){
              this.router.navigate(['kyc-details', this.md5Id])
            }
            else if(this.router.url.includes('/cust-payment-verify-customer/')){
              this.router.navigate(['cust-payment', this.md5Id])
            }
            else{
              this.router.navigate(['customer-basic-details'])
            }
           
            this.tosterservice.success(res.responseMessage);
            this.mobileverified = true;
            // if (!this.entityType) {
            //   this.router.navigate(['aadhar/', this.md5Id]);
            // } else {
            //   this.router.navigate(['aadhar/partner', this.md5Id]);
            // }
          }
        } else {
          this.isDisableVerify = false;
          // this.router.navigate(['customer-basic-details'])
          this.mobileService.isVerifyCustomer = false
          this.tosterservice.error(res.responseMessage);
        }
      })
    } else {
      this.isDisableVerify = false;
      this.tosterservice.error("OTP is required");
    }
  }

  // onMobileVerify(){
  //   this.isVerifybtn = true;

  // }
}
