import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustPaymentService } from '../cust-payment.service';
import { TokenStorageService } from '../../../auth/token-storage-service';
import { ResePwdServiceService } from '../../reset-password/rese-pwd-service.service';
import { AppConfig } from '../../../app.config';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-cust-payment',
  templateUrl: './cust-payment.component.html',
  styleUrls: ['./cust-payment.component.scss']
})
export class CustPaymentComponent implements OnInit {
  @ViewChild('mymodal', { static: true }) mymodalRef: ElementRef;
  partnerId: any;
  userName:any;
  paymentGatewayList = [];
  custPayment: any;
  defualtPaymentGateway;
  paymentGatewayId: number;
  isClassical: boolean
  BackendFinalurl:string;
  paymentFulladdres:any
  backEndImage: string;
  plandetails = [];
  custPayments: any;
  rechargeSubmitBtn: boolean = false;
  vasIdDtos = [];
  chargeIdDtos = [];
  FormGroup: FormGroup;
  invalidUserNameAsPerApi: boolean = false;
  invalidUserNameAsPerApiMsg: string = "";
  isuserName: boolean=false;
  baseUrl: string;
  md5Id: string;
  isSessionexpire: boolean=false;
  isEsigned:boolean = false;
  esignUrl:any;
  isResFromEsign:boolean = false
  errorFormEsign:any;

  constructor(public custPurchaseService: CustPaymentService,
    private modalService: NgbModal,
    private appConfig: AppConfig,
    private resetPasswordService:ResePwdServiceService,
    private tokenService: TokenStorageService,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.backEndImage = this.appConfig.config.baseURLApi;
    this.BackendFinalurl = this.backEndImage.split('/')[2];
  }

  ngOnInit() {
    this.md5Id = this.route.snapshot.paramMap.get('id');
    
    // console.log(this.route.queryParams)
    // console.log(this.route.queryParamMap)
    this.route.queryParamMap.subscribe(params => {
      console.log(params.get('client_id'))
      if(params.get('client_id')!=null){
        this.custPurchaseService.processDoc(this.md5Id).subscribe((res)=>{
          if(res.responseCode==200){
            this.md5Id = res.data.leadMD5Token
            this.initApiCall()
          }
        })
      }
      else{
        this.initApiCall()
      }
    });
   
    
   
    // if (this.resetPasswordService.isVerifyCustomer) {
      
    // }
    // else{
    //   this.router.navigate(['cust-payment-verify-customer',this.md5Id])
    // }
  }

  checkMobileVerification(){
    if(this.md5Id){
      this.resetPasswordService.checkMobileVerify(this.md5Id).subscribe((res)=>{
        if(res.responseCode==200){
          if(!res.data.isVerified){
            this.router.navigate(['cust-payment-verify-customer', this.md5Id])
            return
          }
          else{
            this.getDataById(this.md5Id);
            this.getEsignInfo(this.md5Id)
          }
        }
      })
    }
  }

  initApiCall(){
    const baseUrl = `${location.protocol}//${location.host}`;
    const currentUrl = this.router.url;
    const fullUrl = `${baseUrl}${'/#'}${currentUrl}`;
    if(fullUrl){
      const urlFromBackend = fullUrl
      const hashIndex = urlFromBackend.indexOf('#')
      const nextSlashIndex = urlFromBackend.indexOf('/', hashIndex + 2)
      this.baseUrl = urlFromBackend.substring(0, nextSlashIndex + 1);
      let obj={
         "url":this.baseUrl,
          "leadMD5Token":this.md5Id
      }
      this.resetPasswordService.sessionexpire(obj).subscribe((res)=>{
        if(res.responseCode==200){
          this.isSessionexpire=res.data.sessionExpired
          if(this.isSessionexpire){
            this.router.navigate(['/session-expire'])
            return
            }
          else{
            this.checkMobileVerification()
          }
        }
      })
    }
  }
  getEsignInfo(id){
    this.custPurchaseService.getEsignInfo(id).subscribe((res)=>{
      if (res && res.responseCode === 200) {
        this.isEsigned = res.data.isESigned
        this.esignUrl = res.data.url
        this.isResFromEsign = true
        this.errorFormEsign = res.data.error
      } else {
        this.toaster.error(res.responseMessage)
      }
    }, err => {
      this.toaster.error(err.error.status + " " + err.error.ERROR)
    })
  }

  getDataById(id: any) {
    this.custPurchaseService.place(id).subscribe((res) => {
      this.custPayment = res.data;
      if(this.custPayment.userName){
      this.isuserName=true
      this.userName=this.custPayment.userName
      }
      this.custPayment.addressList.forEach((res)=>{
        if(res.addressType=='payment'){
          this.paymentFulladdres=res.fullAddress
        }
      })
      if (res && res.responseCode === 200) {
        this.getPaymentQateway();
      } else {
        this.toaster.error(res.responseMessage)
      }
    }, err => {
      this.toaster.error(err.error.status + " " + err.error.ERROR)
    })
  }


  getPaymentQateway() {
    this.custPurchaseService.getPaymentGatewayByPartnerLead(this.custPayment.partnerId).subscribe(response => {
      this.paymentGatewayList = response.dataList;
      response.dataList.forEach(element => {

        if (element.defaultpgflag === "Yes") {
          this.defualtPaymentGateway = element.paymentGateWayId;
          this.eventCheck(element);
        }
      });
    })
  }

  open(content) {
    if(!this.userName){
      this.toaster.error('Please Create Username First!');
      return;
    }
    if(!this.isEsigned && this.isResFromEsign && this.errorFormEsign==null){
      this.toaster.error('Please eSign Document First!');
      return;
    }else if(!this.isResFromEsign){
      this.toaster.info("We’re still waiting on details for the e-sign document. Thank you for your patience.");
      return;
    }
    this.getPaymentQateway();
    this.modalService.open(content)
  }
  createUsername(content) {
    this.invalidUserNameAsPerApi=false
    this.invalidUserNameAsPerApiMsg=''
    this.setvalidation()
    this.modalService.open(content,{ size: 'xl', backdrop: 'static' })
    if(this.isuserName){
      this.userName=this.custPayment.userName
    }
  }

  eventCheck(paymentGateway) {
    if (paymentGateway) {
      this.paymentGatewayId = paymentGateway.paymentGateWayId;
    }
    else {
      this.paymentGatewayId = 1;
    }
  }

  insertPlaceOrder(mode) {

    if (this.paymentGatewayId == null) {
      this.toaster.error('Please Select One Payment Gateway');
      return;
    }
    let placeOrder = {
      "leadCustId": this.custPayment.id,
      "leadMD5Id": this.custPayment.leadMd5Token,
      "orderType": "LEAD PAYMENT",
      "finalAmount": this.custPayment.totalAmount,
      "pgid": this.paymentGatewayId,
    };

    this.custPurchaseService.insertPlaceOrder(placeOrder).subscribe((result) => {
      if (result) {
        if (result.responseCode == 200 && result.data) {
          this.modalService.dismissAll();
          this.rechargeSubmitBtn = false;
          if (result.data.amount === 0 && result.data.isPaymentRemaining === false) {

            this.router.navigate(['recharge/payment-status'], { queryParams: { txnid: result.data.pgDetails.txnid } });

          } else {
            this.router.navigate(['redirecttopayment/', result.data.uniqueId]);
          }
        }
        else if (result.responseCode !== 200) {
          this.rechargeSubmitBtn = true;
          this.closeModal();
          let md5Id = this.route.snapshot.paramMap.get('id');
          this.getDataById(md5Id);
          this.toaster.error(result.responseMessage, "Error")
        }
      }
    }, err => {
      this.toaster.error(err.error.status + " " + err.error.ERROR);
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.rechargeSubmitBtn = false;
  }
  setvalidation(){
  this.FormGroup = new FormGroup({
    userName: new FormControl("",[Validators.required,this.cannotContainSpace,Validators.pattern('^[a-zA-Z0-9_]+$')]),
  });
  }

  cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if(control && control.value && (control.value as string).indexOf(' ') >= 0){
        return {cannotContainSpace: true}
    }
    return null;
  }
  checkUniqueUserName(el) {
    let value = el.target.value;
    let obj={
      value:value,
      leadMD5Id:this.custPayment.leadMd5Token
    }
    if (value && value.length) {
      if(!this.spaceCheck(value)){
        this.invalidUserNameAsPerApi = false;
        this.custPurchaseService.checkUniqueCustomerByUsername(obj).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidUserNameAsPerApi = true;
            this.invalidUserNameAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidUserNameAsPerApi = false;
            this.invalidUserNameAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      }
    } else {
      this.invalidUserNameAsPerApi = false;
      this.invalidUserNameAsPerApiMsg = '';
    }
  }
  spaceCheck(value){
    return value.indexOf(' ') >= 0;
  }

  onTouchedandChange() {
    let value = this.FormGroup.value;
    this.onChange(value);
    this.onTouched(value);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  clearMsgUnique() {
    if (
      this.invalidUserNameAsPerApi === true &&
      this.FormGroup.get("userName").valid === false
    ) {
      this.invalidUserNameAsPerApiMsg = "";
    }
  }
  onClickSaveForm(){
    if (this.FormGroup.invalid && !this.invalidUserNameAsPerApi) {
      this.toaster.error('Please fill all required details');
      return
    }
    else{
      let obj={
         "leadMD5Id":this.custPayment.leadMd5Token,
          "userName":this.userName
      }
      this.custPurchaseService.saveuserName(obj).subscribe((res)=>{
        if(res.responseCode==200){
          this.modalService.dismissAll();
          this.getDataById(this.custPayment.leadMd5Token);
          this.isuserName=true
          this.toaster.success('Username Saved Successfully');
        }
      })
      
    }
  }
  closeModalusername(){
    this.modalService.dismissAll()
  }

  onESign(){
    if(!this.isEsigned && this.isResFromEsign && this.errorFormEsign==null){
      window.open(this.esignUrl, "_self");
    }
    else if(this.isResFromEsign && this.errorFormEsign){
      this.toaster.error(this.errorFormEsign);
    }
    else if(this.isResFromEsign){
      this.toaster.success('eSign already done');
    }
  }
}
