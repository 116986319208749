export class CustomerBasicDetails {
    title: string
    firstName: string
    middleName: string
    lastName: string
    gstNo: string
    previousIsp:any
    onu:any
    panNo: string
    userName: string;
    leadSource:any;
    sourceOfRelative:any
    relativeUsername:any
    aadhar: String
    mobileNo: string
    alternateMobileNo: string
    planName: string
    latitude: any
    mobileMd5:any
    longitude: any
    email: string
    telephoneLine: boolean = false
    staticIp: boolean = false
    status: string = 'Details Captured'
    sameAsPresent: boolean = true
    leadOrigin: string
    addressList: Array<addressDTO>
}
export class addressDTO {
    addressType: string
    blockNo: String
    address1: string
    landmark: String
    typeOfProperty: String
    pincodeId: number
    countryId: number
    stateId: number
    districtId: number
    talukaId: number
    constituencyId: number
    cityId: number
    areaId: number
    propertyType: String
    buildingId: number
}
